import { FC } from "react";
import { SeccionTextoImagen } from "../../../../components";
import { ServiciosResp } from "../../../../interfaces/servicios";

interface Props {
    servicios: ServiciosResp | null;
}

export const SegundaSeccion: FC<Props> = ({ servicios }) => {
    return (
        <section>
            <SeccionTextoImagen 
                imagen={servicios?.acf.imagen_secundaria || ''} 
                texto={servicios?.acf.parrafo || ''} 
                tituloPrincipal={servicios?.acf.titulo || ''} />
        </section>
    )
}
