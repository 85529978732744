import { FC } from "react";
import { SeccionTextoImagen } from "../../../../components";
import { VehiculosLigerosResp } from "../../../../interfaces/vehiculosLigeros";

interface Props {
    vehiculosLigeros: VehiculosLigerosResp | null;
}

export const SegundaSeccion: FC<Props> = ({ vehiculosLigeros }) => {
    return (
        <section>
            <SeccionTextoImagen 
                imagen={vehiculosLigeros?.acf.imagen_secundaria || ''} 
                texto={vehiculosLigeros?.acf.parrafo || ''} 
                tituloPrincipal={vehiculosLigeros?.acf.titulo || ''} />
        </section>
    )
}
