import { FC, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContactoResp } from '../../interfaces/contacto';
import { VehiculosLigerosResp } from '../../interfaces/vehiculosLigeros';
import { CuartaSeccion, PrimeraSeccion, SegundaSeccion, TerceraSeccion } from '../../pages/vehiculosLigeros/sections';


interface Props {
    vehiculosLigeros: VehiculosLigerosResp[] | null;
    contacto: ContactoResp | null;
}

export const VehiculosLigeros: FC<Props> = ({ vehiculosLigeros, contacto }) => {
    useEffect(()=>{
        document.title = 'Osania - Vehículos Ligeros';
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PrimeraSeccion vehiculosLigeros={vehiculosLigeros ? vehiculosLigeros[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SegundaSeccion vehiculosLigeros={vehiculosLigeros ? vehiculosLigeros[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TerceraSeccion vehiculosLigeros={vehiculosLigeros ? vehiculosLigeros[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CuartaSeccion contacto={contacto} />
                </Grid>
            </Grid>
        </Box>
    )
}
