import { FC } from 'react';

import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';

import styles from './estilos.module.css';

import sombra from '../../../../assets/img/carrucel/sombra carrusel.png';
import logo from '../../../../assets/img/otros/logotipo-blanco.png';
import expandir from '../../../../assets/icons/expandir.svg';

import pleca from '../../../../assets/img/carrucel/pleca.png';
import { InicioResp } from '../../../../interfaces';
import { Link } from 'react-router-dom';
import { HashLink as LinkAnchor } from 'react-router-hash-link';
import { imagenesMobile } from '../../../../utils';

import useMediaQuery from '@mui/material/useMediaQuery';

interface PropsTexto {
    titulo: string;
    texto: string;
}

const ContenedorTexto: FC<PropsTexto> = ({ titulo, texto }) => {
    return (
        <div className={styles['contenedor-texto']} style={{
            backgroundImage: `url(${pleca})`
        }}>
            <h3 className={styles.h3}>{titulo}</h3>
            <p className={styles.p}>
                {texto}
            </p>
        </div>
    )
}

/**
 * Slide content
 */
interface PropsSlide {
    imagen: string;
    titulo: string;
    texto: string;
}

const SlideContent: FC<PropsSlide> = ({ imagen, titulo, texto }) => {
    return (
        <div className={styles['contenedor-slide']} style={{
            backgroundImage: `url(${imagen})`
        }}>
            <img className={styles.sombra} src={sombra} alt="" />
            <ContenedorTexto
                titulo={titulo}
                texto={texto} />
            <LinkAnchor to="/#home-trabajamos-en-equipo" 
                className={styles['contenedor-flecha']}>
                <img className={styles.expandir} src={expandir} alt="" />
            </LinkAnchor>
        </div>
    )
}


interface Props {
    inicio: InicioResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ inicio }) => {
    const matches = useMediaQuery('(max-width:600px)');
    return (

        <section id="inicio">
            <Swiper
                modules={[Navigation, Pagination, EffectFade, Autoplay]}
                navigation
                pagination={{ clickable: true }}
                spaceBetween={0}
                slidesPerView={1}
                onSlideChange={() => { }}
                onSwiper={(swiper) => { }}
            >

                {
                    inicio?.acf?.slides?.map(({ imagen, texto, titulo }, i) => (
                        <SwiperSlide key={`inicio-slide-${i}`}>
                            <SlideContent
                                imagen={matches ? imagenesMobile[i] : imagen}
                                //imagen={imagen}
                                texto={texto}
                                titulo={titulo} />
                        </SwiperSlide>
                    ))
                }


            </Swiper>
            <Link to="/">
                <img className={styles.logo} src={logo} alt="" />
            </Link>
        </section>
    )
}