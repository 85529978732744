import { FC, RefObject, useRef, useState } from 'react';

import styles from './estilos.module.css';
import { InicioResp } from '../../interfaces';
import { animateCSS } from '../../utils';

interface PropsStep {
  contenedor: RefObject<HTMLDivElement>;
  index: number;
  fijo: number;
  setFijo: (index: number) => void;
  primero: boolean;
  ultimo: boolean;
  nombre: string;
}

const Step: FC<PropsStep> = ({ index, fijo, setFijo, nombre, primero, ultimo, contenedor }) => {

  const [active, setActive] = useState(false);

  const handleClick = (i: number, contenedor: RefObject<HTMLDivElement>) =>{
    setFijo(i);
    animateCSS(contenedor.current!,'fadeIn');
  }

  return (
    <div className={styles['contenedor-step']}>
      <div className={styles['contenedor-bullet-linea']}>
        <div className={primero ? styles['linea-oculta'] : styles.linea}></div>
        <div className={styles['contenedor-bullet']}
          onClick={() => handleClick(index, contenedor) }
          onMouseOver={() => setActive(true)}
          onMouseOut={() => setActive(false)}>
          <div className={(active || fijo === index) ? styles['bullet-active'] : styles.bullet} ></div>
        </div>
        <div className={ultimo ? styles['linea-oculta'] : styles.linea}></div>
      </div>

      <p className={styles.p}>{nombre}</p>
    </div>
  )
}

interface Props {
  inicio: InicioResp | null;
}

export const Stepper: FC<Props> = ({ inicio }) => {
  const [fijo, setFijo] = useState(0);
  const contenedor = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.contenedor}>

      <div className={styles['contenedor-steps']}>
        {
          inicio?.acf?.valores?.map(({ titulo }, i) => (
            <Step key={`step-${i}`}
              index={i}
              fijo={fijo}
              setFijo={setFijo}
              primero={i === 0}
              ultimo={i === inicio?.acf?.valores?.length! - 1}
              nombre={titulo}
              contenedor={contenedor} />
          ))
        }
      </div>

      <div ref={contenedor} className={`${styles['contenedor-datos']}`}>
        <img className={styles.img} 
          src={inicio?.acf?.valores ? inicio?.acf?.valores[fijo].imagen : ''} alt="" 
          onLoad={()=>{animateCSS(contenedor.current!,'fadeIn')}} />
        <div className={styles['contenedor-texto']}>
          <h3 className={styles.h3}>
            {inicio?.acf?.valores ? inicio?.acf?.valores[fijo].titulo : ''}
          </h3>
          <p className={styles.descripcion}>
            {inicio?.acf?.valores ? inicio?.acf?.valores[fijo].parrafo : ''}
          </p>
        </div>
      </div>
    </div>
  )
}
