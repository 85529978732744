import { FC, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContactoResp } from '../../interfaces/contacto';
import { ServiciosResp } from '../../interfaces/servicios';
import { CuartaSeccion, PrimeraSeccion, SegundaSeccion, TerceraSeccion } from '../../pages/servicios/sections';

interface Props {
    servicios: ServiciosResp[] | null;
    contacto: ContactoResp | null;
}

export const Servicios: FC<Props> = ({ servicios, contacto }) => {
    useEffect(()=>{
        document.title = 'Osania - Servicios';
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PrimeraSeccion servicios={servicios ? servicios[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SegundaSeccion servicios={servicios ? servicios[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TerceraSeccion servicios={servicios ? servicios[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CuartaSeccion contacto={contacto} />
                </Grid>
            </Grid>
        </Box>
    )
}
