import { FC } from "react"
import { PiezasResp } from "../../../../interfaces/piezas"
import { SeccionImagenLogo } from "../../../../components";

interface Props {
    piezas: PiezasResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ piezas }) => {
    return (
        <section>
            <SeccionImagenLogo imagen={piezas?.acf.imagen_cover || ''} />
        </section>
    )
}
