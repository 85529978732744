import { CSSProperties, FC } from "react";

import styles from './estilos.module.css';

interface Props {
  textos: string[];
  estilosTextos?: CSSProperties[];
  estiloContenedor?: CSSProperties;
}

export const TextoSubrayado: FC<Props> = ({ textos, estiloContenedor = {}, estilosTextos=[] }) => {
  
  if(estilosTextos && estilosTextos.length < textos.length){
    let numero = textos.length - estilosTextos.length;
    while(numero > 0){
      estilosTextos.push({});
      numero--;
    }
  }

  if(!estilosTextos){
    estilosTextos = [];
    textos.forEach(()=>estilosTextos?.push({}));
  }

  return (
    <h3 className={styles.h3} style={estiloContenedor}>
      {
        textos.map((texto, i) => (
          <span key={i} className={styles.span} style={estilosTextos[i]} >{texto}</span>
        ))
      }
    </h3>
  )
}
