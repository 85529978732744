import { FC } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { CartaPiezas, CartaVehiculosLigeros } from "../";
import { PiezasResp } from "../../interfaces/piezas"


import styles from './styles.module.css';

import marca from '../../assets/img/otros/logo-invertido.png';
import { ServiciosResp } from '../../interfaces/servicios';
import { CartaServicios } from '../CartaServicios';
import { VehiculosLigerosResp } from '../../interfaces/vehiculosLigeros';
import { VehiculosPesadosResp } from '../../interfaces/vehiculosPesados';
import { CartaVehiculosPesados } from '../CartaVehiculosPesados';

interface Props {
    piezas?: PiezasResp | null;
    servicios?: ServiciosResp | null;
    vehiculosLigeros?: VehiculosLigerosResp | null;
    vehiculosPesados?: VehiculosPesadosResp | null;
}


export const ListadoProductos: FC<Props> = ({ piezas, servicios, vehiculosLigeros, vehiculosPesados }) => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <div className={styles.contenedor}>
            <div className={styles['contenedor-listado']}>
                {
                    piezas?.acf?.catalogo?.map(({ titulo_seccion, piezas }, i) => (
                        <div key={`contenedor-piezas${i}`}>
                            {
                                piezas.map(({ titulo, aplicacion, archivo_descargable,
                                    arrastre, especificaciones, imagen, sectores, color_de_fondo }, j) => (
                                    <CartaPiezas
                                        key={`listado-piezas${j}-${i}`}
                                        titulo_seccion={titulo_seccion}
                                        color={color_de_fondo === 'gris' ? '#efefef' : '#fff'}
                                        index={j}
                                        titulo={titulo}
                                        aplicacion={aplicacion}
                                        archivo_descargable={archivo_descargable}
                                        arrastre={arrastre}
                                        especificaciones={especificaciones}
                                        imagen={imagen}
                                        sectores={sectores} />
                                ))
                            }
                        </div>
                    ))
                }

                {
                    servicios?.acf.catalogo?.map(({ imagen, parrafo, titulo_principal, titulo_secundario,
                        color_de_fondo }, i) => (
                        <CartaServicios
                            key={`cs-${i}`}
                            color={color_de_fondo === 'gris' ? '#efefef' : '#fff'}
                            imagen={imagen}
                            parrafo={parrafo}
                            titulo_principal={titulo_principal}
                            titulo_secundario={titulo_secundario} />
                    ))
                }


                {
                    vehiculosLigeros?.acf?.catalogo?.map(({ titulo_seccion, piezas }, i) => (
                        <div key={`contenedor-piezas-vl${i}`}>
                            {
                                piezas.map(({ titulo, aplicacion, archivo_descargable, color_de_fondo,
                                    arrastre, especificaciones, imagen, rango_inversion }, j) => (
                                    <CartaVehiculosLigeros
                                        key={`listado-piezas-vl${i}-${j}`}
                                        titulo_seccion={titulo_seccion}
                                        rango_inversion={rango_inversion}
                                        color={color_de_fondo === 'gris' ? '#efefef' : '#fff'}
                                        index={j}
                                        titulo={titulo}
                                        aplicacion={aplicacion}
                                        archivo_descargable={archivo_descargable}
                                        arrastre={arrastre}
                                        especificaciones={especificaciones}
                                        imagen={imagen} />
                                ))
                            }
                        </div>
                    ))
                }

                {
                    vehiculosPesados?.acf?.catalogo?.map(({ titulo_seccion, piezas }, i) => (
                        <div key={`contenedor-piezas-vp${i}`}>
                            {
                                piezas?.map(({ titulo, aplicacion, archivo_descargable, especificaciones,
                                    imagen, rango_inversion, color_de_fondo }, j) => (
                                    <CartaVehiculosPesados
                                        key={`listado-piezas-vp${j}-${i}`}
                                        titulo_seccion={titulo_seccion}
                                        rango_inversion={rango_inversion}
                                        color={color_de_fondo === 'gris' ? '#efefef' : '#fff'}
                                        index={j}
                                        titulo={titulo}
                                        aplicacion={aplicacion}
                                        archivo_descargable={archivo_descargable}
                                        especificaciones={especificaciones}
                                        imagen={imagen} />
                                ))
                            }
                        </div>
                    ))
                }

            </div>
            {!matches && <img className={styles.marca} src={marca} alt="" />}
        </div>
    )
}
