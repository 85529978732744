import { FC } from "react"

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-hook-inview'

import { TextoSubrayado } from '../TextoSubrayado'

import { obtenerClasesAnimacion, obtenerPrimerasLetras, obtenerUltimasLetras } from '../../utils';

import { Especificacione } from "../../interfaces/vehiculosLigeros";

import styles from './styles.module.css'
import { rangoInversion } from '../../utils/rangoInversion/index';

import { HashLink as Link } from '@xzar90/react-router-hash-link';

interface PropsEspecificacion {
  icono: string;
  titulo: string;
  texto: string;
}

const Especificacion: FC<PropsEspecificacion> = ({ icono, texto, titulo }) => {
  return (
    <div className={styles['contenedor-especificacion']}>
      <img className={styles.icono} src={icono} alt="" />
      <p className={styles['titulo-especificacion']}>{titulo}</p>
      <p className={styles['descripcion-especificacion']}>{texto}</p>
    </div>
  )
}

interface Props {
  titulo_seccion?: string;
  color: string;
  index: number;
  titulo: string;
  aplicacion: string;
  archivo_descargable: boolean | string;
  arrastre: string;
  especificaciones: Especificacione[];
  imagen: string;
  rango_inversion: string;
}

export const CartaVehiculosLigeros: FC<Props> = ({ titulo_seccion, color, index, titulo, aplicacion, archivo_descargable,
  arrastre, especificaciones, imagen, rango_inversion }) => {

  const matches = useMediaQuery('(max-width:600px)');
  const [contenedor, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true
  })
  return (
    <Grid container spacing={1} sx={{
      minHeight: 669,
      backgroundColor: color,
      padding: matches ? '20px 0px' : '0px'
    }}>
      <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>

      <Grid container item xl={10} lg={10} md={10} sm={10} xs={10}>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {index === 0 && titulo_seccion && <div ref={contenedor}
            className={`${isVisible ? `${obtenerClasesAnimacion('animate__fadeIn')} ${styles['contenedor-texto-subrayado']}` 
              : `${styles['contenedor-texto-subrayado']}`}`}>
            <TextoSubrayado
              estiloContenedor={{
                color: '#1d3d62',
                fontSize: matches ? 26 : 40,
                whiteSpace: matches ? 'initial' : 'pre',
                fontWeight: 100,
                margin: 0,
                padding: matches ? '5px 0px' : '36px 0px',
              }}
              textos={[
                `     ${obtenerPrimerasLetras(titulo_seccion, 5)}`,
                obtenerUltimasLetras(titulo_seccion, 5)
              ]}
              estilosTextos={[
                {
                  borderBottom: matches ? 'none' : '4px solid #eea660'
                }
              ]}
            /></div>}
        </Grid>

        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid className={styles['contenedor-imagen']} item xl={4} lg={4} md={12} sm={12} xs={12}>
            <img className={styles.img} src={imagen} alt="" />
          </Grid>

          <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
 
          <Grid container item xl={7} lg={7} md={12} sm={12} xs={12}>
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Grid className={styles['contenedor-titulos']} item xl={8} lg={7} md={7} sm={7} xs={12}>
                <h3 className={styles.h3}>{titulo}</h3>
              </Grid>
              <Grid className={styles['contenedor-titulos']} item xl={4} lg={5} md={5} sm={5} xs={12}>
                <div className={styles['contenedor-botones']}>
                  <Link className={styles.btn} to="/vehiculos-ligeros#contacto">
                    Cotizar
                  </Link>
                  <Link className={`${styles.btn} ${styles['btn-descarga']}`}
                    //href={archivo_descargable !== true && archivo_descargable !== false ? archivo_descargable : ''}
                    //target="_blank" rel="noreferrer"
                    to="/vehiculos-ligeros#contacto"
                    >
                    Descargar ficha técnica
                  </Link>
                </div>
              </Grid>
              <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className={styles.p}><span className={styles.span}>Aplicación: </span>{aplicacion}</p>
                  <p className={styles.p}><span className={styles.span}>Arrastre: </span>{arrastre}</p>
                </Grid>
                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {
                    especificaciones.map(({ icono, texto, titulo }, i) => (
                      <Grid className={styles['contenedor-tamanios']} container item xl={2} lg={2} md={3} sm={4} xs={6} justifyContent='center'
                        key={`espeficicacion-vl-${index}-${i}`}>
                        <Especificacion
                          icono={icono}
                          texto={texto}
                          titulo={titulo} />

                      </Grid>
                    ))

                  }
                  <p className={`${styles.p} ${styles.rango}`}><span className={styles.span}>
                    Rango de inversión: </span>{
                      rangoInversion(rango_inversion, 5)
                    }
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>

      </Grid>
    </Grid >
  )
}
