import { FC, useState } from 'react';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import useMediaQuery from '@mui/material/useMediaQuery';


import 'swiper/css';
import 'swiper/css/navigation';

import styles from './estilos.module.css';

import flecha from '../../../../assets/img/otros/flecha-naranja-vertical.png';

import { InicioResp, Subservicio } from '../../../../interfaces';
import { Link } from 'react-router-dom';

const urls = [
  '/vehiculos-ligeros',
  '/vehiculos-pesados',
  '/retornables',
  '/piezas',
  '/servicios'
]

/**
 * COMPONENTE TEXTOS DEL SLIDER
 */
interface PropsTextoSlider {
  hover: boolean;
  titulo: string;
  subservicios: Subservicio[];
  url: string;
}

const TextoSlider: FC<PropsTextoSlider> = ({ hover, titulo, subservicios, url }) => {
  return (
    hover ?
      <div className={styles['contenedor-texto-slider']}>
        <img className={styles.flecha} src={flecha} alt="" />
        {/*<Link className={styles['link-url']} to={url}>*/}
          <h3 className={styles['h3-hover']}>{titulo}</h3>
        {/*</Link>*/}
      </div>
      :
      <div className={styles['contenedor-texto-slider']}>
        <h3 className={styles.h3}>{titulo}</h3>
        {
          subservicios.map(({ nombre }) => (
            <p key={nombre} className={styles.p}>{nombre}</p>
          ))
        }
      </div>
  )
}

/**
 * COMPONENTE CONTENIDO DEL SLIDER
 */

interface Fondo {
  imagen: string;
  imagenHover: string;
}

interface PropsSlideContent {
  fondo: Fondo;
  titulo: string;
  subservicios: Subservicio[];
  index: number;
}

const SlideContent: FC<PropsSlideContent> = ({ fondo, titulo, subservicios, index }) => {
  const [imagen, setImagen] = useState(fondo.imagen);
  const [hover, setHover] = useState(false);
  return (
    <Link to={urls[index]}>
      <div
        className={styles['contenedor-slider']}
        onMouseOver={() => {
          setImagen(fondo.imagenHover);
          setHover(true);
        }}
        onMouseOut={() => {
          setImagen(fondo.imagen);
          setHover(false);
        }}>
        <img className={styles.img} src={imagen} alt="" />
        <TextoSlider url={urls[index]} hover={hover} titulo={titulo} subservicios={subservicios} />
      </div>
    </Link>

  )
}

interface Props {
  inicio: InicioResp | null;
}

export const TerceraSeccion: FC<Props> = ({ inicio }) => {
  const matches = useMediaQuery('(max-width:600px)');
  const matches1 = useMediaQuery('(min-width:600px) and (max-width:900px)');
  const matches2 = useMediaQuery('(min-width:901px) and (max-width:1200px)');

  const slidePerView = ()=> {
    if (matches) {
      return 1
    }
    if (matches1) {
      return 2
    }
    if (matches2) {
      return 3
    }
    return 4
  }

  return (
    <section>
      <Swiper
        modules={[Navigation]}
        navigation
        spaceBetween={0}
        slidesPerView={slidePerView()}
        onSlideChange={() => { }}
        onSwiper={(swiper) => { }}>

        {
          inicio?.acf.servicios?.map(({ imagen, imagen_hover, titulo }, i) => (
            <SwiperSlide key={i}>
              <SlideContent
                index={i}
                fondo={{
                  imagen,
                  imagenHover: imagen_hover
                }}
                titulo={titulo}
                subservicios={inicio?.acf.servicios ? inicio?.acf.servicios[i].subservicios : []} />
            </SwiperSlide>
          ))
        }

      </Swiper>
    </section>
  )
}
