import { FC } from "react"
import { Contacto } from "../../../../components/Contacto";
import { ContactoResp } from "../../../../interfaces/contacto"

interface Props {
    contacto: ContactoResp | null;
}

export const CuartaSeccion: FC<Props> = ({ contacto }) => {
    return (
        <section>
            <Contacto contacto={contacto}
                estilosContenedor={{
                    backgroundColor: '#1d3d62'
                }}
                estilosTitulo={{
                    color: '#fff'
                }}
                estilosInput={{
                    backgroundColor: '#fff',
                    color: '#1d3d62'
                }}
                estilosTextArea={{
                    backgroundColor: '#fff',
                    color: '#1d3d62'
                }} />
        </section>
    )
}
