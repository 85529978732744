import { FC } from "react";
import { SeccionImagenLogo } from "../../../../components";
import { VehiculosLigerosResp } from "../../../../interfaces/vehiculosLigeros";

interface Props {
    vehiculosLigeros: VehiculosLigerosResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ vehiculosLigeros }) => {
    return (
        <section>
            <SeccionImagenLogo imagen={vehiculosLigeros?.acf.imagen_cover || ''} />
        </section>
    )
}
