import { FC, useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-hook-inview'

import { Titulo, TextoSubrayado } from '../../components';

import styles from './styles.module.css';

import marcaAgua from '../../assets/img/home/secciones/segunda/marca-agua.png';
import { obtenerClasesAnimacion } from '../../utils';

interface Props {
    tituloPrincipal: string;
    texto: string;
    imagen: string;
}

export const SeccionTextoImagen: FC<Props> = ({ tituloPrincipal, texto, imagen }) => {
    const [titulo, setTitulo] = useState(['', '']);
    const matches = useMediaQuery('(max-width:600px)');
    const [contenedor, isVisible] = useInView({
        threshold: 0.1,
        unobserveOnEnter: true
    })

    useEffect(() => {
        if (tituloPrincipal) {
            const primeraPalabra = tituloPrincipal.split(' ')[0] || '';
            const resto = tituloPrincipal.replace(`${primeraPalabra} `, '') || '';
            setTitulo([primeraPalabra, resto]);
        }

    }, [tituloPrincipal])


    return (
        <div className={styles['segunda-seccion']} >

            <div ref={contenedor} 
                className={isVisible ? `${obtenerClasesAnimacion('animate__zoomIn')} ${styles['contenedor-textos']}` : `${styles['contenedor-textos']}`} >
                {matches ?
                    <Titulo titulo={<>{tituloPrincipal}</>} />
                    : <Titulo titulo={<>{titulo[0]} <br /><span style={{
                        fontSize: '55px',
                        fontFamily: 'Bebas'
                    }}> {titulo[1]} </span></>} />}
                <p className={styles.texto}>
                    {texto}
                </p>
            </div> 

            <img className={styles.img} src={imagen} alt="" />

            {!matches && <TextoSubrayado estiloContenedor={{
                position: 'absolute',
                zIndex: '10',
                bottom: 25,
                left: '50%',
                transform: 'translate(-50%,0)'
            }} estilosTextos={[
                {
                    color: '#fff',
                    borderBottom: '3px solid #fa7513'
                }, {
                    borderBottom: '3px solid #fa7513'
                }
            ]} textos={['CATÁLOGO ', 'SOLUCIONES:']} />}

            {!matches && <img className={styles['marca-agua']} src={marcaAgua} alt="logo" />}
        </div>
    )
}
