import { Link } from "react-router-dom"

import useMediaQuery from '@mui/material/useMediaQuery';

import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import FactoryIcon from '@mui/icons-material/Factory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';


import styles from './styles.module.css'

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const iconos = [
    <HomeIcon />,
    <SettingsApplicationsIcon />,
    <FactoryIcon />,
    <SupportAgentIcon />,
    <TimeToLeaveIcon />,
    <DirectionsBusIcon />
]

const rutas = [
    {
        texto: 'INICIO',
        url: '/'
    },
    {
        texto: 'PIEZAS',
        url: '/piezas'
    },
    {
        texto: 'RETORNABLES',
        url: '/retornables'
    },
    {
        texto: 'SERVICIOS',
        url: '/servicios'
    },
    {
        texto: 'VEHÍCULOS LIGEROS',
        url: '/vehiculos-ligeros'
    },
    {
        texto: 'VEHÍCULOS PESADOS',
        url: '/vehiculos-pesados'
    }
]

export const Header = () => {

    const matches = useMediaQuery('(max-width:600px)');

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                {rutas.map(({ texto, url }, index) => (
                    <Link key={texto} to={url} className={styles['link-menu']} >
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    {iconos[index]}
                                </ListItemIcon>
                                <ListItemText primary={texto} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </Box>
    );

    return (
        <header className={styles.header}>
            {/*!matches && <ul className={styles.ul}>
                <li><Link to="/" className={styles.link}>Inicio</Link></li>
                <li><Link to="/piezas" className={styles.link}>Piezas</Link></li>
                <li><Link to="/retornables" className={styles.link}>Retornables</Link></li>
                <li><Link to="/servicios" className={styles.link}>Servicios</Link></li>
                <li><Link to="/vehiculos-ligeros" className={styles.link}>Vehículos ligeros</Link></li>
                <li><Link to="/vehiculos-pesados" className={styles.link}>Vehículos pesados</Link></li>
            </ul>*/}

            {<React.Fragment key={'left'}>
                <div className={styles['contenedor-boton']}>
                    <button className={styles['boton-idioma']}>
                        ENGLISH
                    </button>
                    <Button onClick={matches ? toggleDrawer('left', true) : toggleDrawer('right', true)}>
                        <MenuIcon sx={{
                            color: '#fff',
                            fontSize: 40
                        }} />
                    </Button>
                </div>
                <SwipeableDrawer
                    anchor={matches ? 'left' : 'right'}
                    open={matches ? state['left'] : state['right']}
                    onClose={matches ? toggleDrawer('left', false) : toggleDrawer('right', false)}
                    onOpen={matches ? toggleDrawer('left', true) : toggleDrawer('right', true)}
                >
                    {list(matches ? 'left' : 'right')}
                </SwipeableDrawer>
            </React.Fragment>}


        </header>
    )
}
