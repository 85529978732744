import { FC, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { RetornableResp } from '../../interfaces/retornable/index';
import { CuartaSeccion, PrimeraSeccion, SegundaSeccion, TerceraSeccion } from '../../pages/retornables/sections';
import { ContactoResp } from '../../interfaces/contacto';

interface Props {
    retornable: RetornableResp[] | null;
    contacto: ContactoResp | null;
}

export const Retornables: FC<Props> = ({ retornable, contacto }) => {
    useEffect(()=>{
        document.title = 'Osania - Retornables';
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PrimeraSeccion retornable={retornable ? retornable[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SegundaSeccion retornable={retornable ? retornable[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TerceraSeccion retornable={retornable ? retornable[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CuartaSeccion contacto={contacto} />
                </Grid>
            </Grid>
        </Box>
    )
}
