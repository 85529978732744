import { FC } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Stepper, TextoSubrayado } from '../../../../components';
import { InicioResp } from '../../../../interfaces';
import styles from './estilos.module.css';

interface Props {
  inicio: InicioResp | null;
}

export const CuartaSeccion: FC<Props> = ({ inicio }) => {
  const matches = useMediaQuery('(max-width:600px)');
  return (
    <section>
      <div className={styles.contenedor}>
        <TextoSubrayado
          estiloContenedor={{
            margin: 0,
            textAlign: 'center',
            color: '#0080c1',
            paddingTop: matches ? 10 : 90,
            fontSize: matches ? '24px' : '36px'
          }}
          textos={['¿POR QUÉ ELEGIR ', 'OSANIA', '?']}
          estilosTextos={[
            {},
            { borderBottom: '3px solid #eea660', color: '#1d3d62', paddingBottom: matches ? '5px' : '15px' }
          ]} />
        <Stepper inicio={inicio} />
      </div>
    </section>
  )
}
