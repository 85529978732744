import { FC } from "react";
import { SeccionTextoImagen } from "../../../../components"
import { RetornableResp } from "../../../../interfaces/retornable"


interface Props{
    retornable: RetornableResp | null;
}

export const SegundaSeccion: FC<Props> = ({retornable}) => {
  return (
    <section>
        <SeccionTextoImagen 
            imagen={retornable?.acf.imagen_cover || ''}
            tituloPrincipal={retornable?.acf.titulo || ''}
            texto={retornable?.acf.parrafo || ''} />
    </section>
  )
}
