import { FC } from 'react';
import { ListadoProductos } from '../../../../components';
import { Boton } from '../../../../components/Boton';

import { ServiciosResp } from '../../../../interfaces/servicios';

import styles from './styles.module.css'

interface Props {
    servicios: ServiciosResp | null;
}

export const TerceraSeccion: FC<Props> = ({ servicios }) => {
    return (
        <section>
            <ListadoProductos servicios={servicios} />
            <div className={styles['contenedor-boton']} >

                <Boton>Volver arriba</Boton>
            </div>
        </section>
    )
}
