import { FC, CSSProperties, useState } from 'react';
import { Link } from 'react-router-dom'

import styles from './styles.module.css';

import marca from '../../assets/img/otros/marca-agua.png';
import { ContactoResp } from '../../interfaces/contacto';
import { Boton } from '../Boton';

interface Props {
  contacto: ContactoResp | null;
  mostrarMarcaAgua?: boolean;
  mostrarBoton?: boolean;
  estilosContenedor?: CSSProperties;
  estilosForm?: CSSProperties;
  estilosTitulo?: CSSProperties;
  estilosInput?: CSSProperties;
  estilosTextArea?: CSSProperties;
}

export const Contacto: FC<Props> = ({ contacto, mostrarMarcaAgua = false, mostrarBoton = false,
  estilosContenedor = {}, estilosForm = {}, estilosTitulo = {}, estilosInput = {}, estilosTextArea = {} }) => {

  const [formulario, setFormulario] = useState({
    nombre: '',
    empresa: '',
    email: '',
    descripcion: '',
    phone: '',
  });

  return (
    <div id="contacto">
      <div className={styles.contenedor} style={estilosContenedor}>
        <form className={styles.form} style={estilosForm}>
          <h3 className={styles.h3} style={estilosTitulo}>{contacto?.acf.titulo}</h3>
          <div className={styles['contenedor-inputs']}>
            <input className={`${styles.input} input-placeholder`} style={estilosInput} onChange={(e) => {
              setFormulario({ ...formulario, nombre: e.target.value });
            }} placeholder='NOMBRE:' />
            <input className={`${styles.input} input-placeholder`} style={estilosInput} onChange={(e) => {
              setFormulario({ ...formulario, phone: e.target.value });
            }} placeholder='TELÉFONO:' />
            <input className={`${styles.input} input-placeholder`} style={estilosInput} onChange={(e) => {
              setFormulario({ ...formulario, empresa: e.target.value });
            }} placeholder='EMPRESA:' />
            <input className={`${styles.input} input-placeholder`} style={estilosInput} onChange={(e) => {
              setFormulario({ ...formulario, email: e.target.value });
            }} placeholder='MAIL:' />
            <textarea className={styles['text-area']} style={estilosTextArea} onChange={(e) => {
              setFormulario({ ...formulario, descripcion: e.target.value });
            }}
              placeholder='BREVE DESCRIPCIÓN DEL PROYECTO:' />
            <div className="g-recaptcha" data-sitekey="6LdS8fsiAAAAADcZKe3DUHkJX1LlV7Z0CBZ3jVVv"></div>
            <div style={{marginTop: 10}}></div>
            <Boton funcion={(e) => {
              e.preventDefault();
              fetch('enviar.php', {
                method: 'POST',
                // eslint-disable-next-line no-undef
                body: JSON.stringify({ ...formulario, captcha: grecaptcha.getResponse() }),
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
              }).then((response) => {
                return response.json();
              }).then((json) => {
                if (json.success) {
                  alert(json.success);
                }
                if (json.error) {
                  alert(json.error);
                }
              });
            }}>Enviar</Boton>
            {mostrarBoton && <Link className={styles.volver} to={{
              pathname: "/",
              hash: "#inicio",
            }}>Volver arriba</Link>}
          </div>

        </form>
        {mostrarMarcaAgua && <img className={styles.marca} src={marca} alt="" />}
      </div>
    </div>
  )
}