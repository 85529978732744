import { FC, useEffect, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-hook-inview'

import styles from './estilos.module.css';

import marcaAgua from '../../../../assets/img/home/secciones/segunda/marca-agua.png';
import { MenuConImagen, Titulo, TextoSubrayado } from '../../../../components';
import { InicioResp } from '../../../../interfaces';
import { obtenerClasesAnimacion } from '../../../../utils';

interface Props {
    inicio: InicioResp | null;
}

export const SegundaSeccion: FC<Props> = ({ inicio }) => {
    const [titulo, setTitulo] = useState(['','']);

    const matches = useMediaQuery('(max-width:600px)');
    const [contenedor, isVisible] = useInView({
        threshold: 0.1,
        unobserveOnEnter: true
    })

    useEffect(() => {
        if (inicio) {
            const primeraPalabra = inicio.acf.titulo?.split(' ')[0] || '';
            const resto = inicio.acf.titulo?.replace(`${primeraPalabra} `,'') || '';
            setTitulo([primeraPalabra,resto]);
        }

    }, [inicio])


    return (
        <section id="home-trabajamos-en-equipo" className={styles['segunda-seccion']} >

            <div ref={contenedor} className={isVisible ? `${obtenerClasesAnimacion('animate__zoomIn')}` : ''}>
                <Titulo titulo={!matches ? <>{titulo[0]} <br /><span style={{
                    fontSize: '60px',
                    fontFamily: 'Bebas'
                }}> {titulo[1]} </span></> : <>{inicio?.acf?.titulo}</>} />
                <p className={styles.texto}>
                    {inicio?.acf.parrafo}
                </p>
            </div>

            <MenuConImagen items={inicio?.acf?.industrias || [{titulo:'', imagen: ''}]} />

            {!matches&&<TextoSubrayado estiloContenedor={{
                position: 'absolute',
                zIndex: '10',
                bottom: 25,
                left: '50%',
                transform: 'translate(-50%,0)'
            }} estilosTextos={[
                {
                    color: '#fff',
                    borderBottom: '3px solid #fa7513'
                }
            ]} textos={['SOLUCIONES ', 'APLICADAS EN:']} />}

            <img className={styles['marca-agua']} src={marcaAgua} alt="logo" />
        </section>
    )
}
