import { FC } from "react";
import { SeccionImagenLogo } from "../../../../components";
import { VehiculosPesadosResp } from "../../../../interfaces/vehiculosPesados";

interface Props {
    vehiculosPesados: VehiculosPesadosResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ vehiculosPesados }) => {
    return (
        <section>
            <SeccionImagenLogo imagen={vehiculosPesados?.acf.imagen_cover || ''} />
        </section>
    )
}
