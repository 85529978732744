import { useEffect, useState } from "react"
import { Routes, Route } from "react-router-dom"

import { forkJoin } from "rxjs"

import { Home } from "./Home"
import { useFetch, useURL } from '../hooks';
import { InicioResp } from "../interfaces";
import { Piezas } from "./Piezas";
import { PiezasResp } from '../interfaces/piezas';
import { ContactoResp } from "../interfaces/contacto";
import { RetornableResp } from "../interfaces/retornable";
import { Retornables } from "./Retornables";
import { Servicios } from './Servicios/index';
import { ServiciosResp } from "../interfaces/servicios";
import { VehiculosLigeros } from "./VehiculosLigeros";
import { VehiculosLigerosResp } from "../interfaces/vehiculosLigeros";
import { VehiculosPesados } from './VehiculosPesados/index';
import { VehiculosPesadosResp } from "../interfaces/vehiculosPesados";

export const Rutas = () => {

  const [inicio, setInicio] = useState<InicioResp[] | null>(null);
  const [piezas, setPiezas] = useState<PiezasResp[] | null>(null);
  const [retornable, setRetornable] = useState<RetornableResp[] | null>(null);
  const [servicios, setServicios] = useState<ServiciosResp[] | null>(null);
  const [vehiculosLigeros, setVehiculosLigeros] = useState<VehiculosLigerosResp[] | null>(null);
  const [vehiculosPesados, setVehiculosPesados] = useState<VehiculosPesadosResp[] | null>(null);

  const [contacto, setContacto] = useState<ContactoResp | null>(null);

  const { peticion } = useFetch();
  const url = useURL();


  useEffect(() => {

    forkJoin({
      inicio: peticion(`${url}/inicio`),
      piezas: peticion(`${url}/piezas`),
      retornable: peticion(`${url}/retornable`),
      servicios: peticion(`${url}/servicios`),
      vehiculos_ligeros: peticion(`${url}/vehiculos_ligeros`),
      vehiculos_pesados: peticion(`${url}/vehiculos_pesados`),
      contacto: peticion(`${url}/inicio/20`)
    }).subscribe(res => {
      setInicio(res.inicio);
      setPiezas(res.piezas);
      setRetornable(res.retornable);
      setServicios(res.servicios);
      setVehiculosLigeros(res.vehiculos_ligeros);
      setVehiculosPesados(res.vehiculos_pesados);
      setContacto(res.contacto);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  return (
    <Routes>
      <Route path="/" element={<Home inicio={inicio} />} />
      <Route path="/piezas" element={<Piezas piezas={piezas} contacto={contacto} />} />
      <Route path="/retornables" element={<Retornables retornable={retornable} contacto={contacto} />} />
      <Route path="/servicios" element={<Servicios servicios={servicios} contacto={contacto} />} />
      <Route path="/vehiculos-ligeros" 
        element={<VehiculosLigeros vehiculosLigeros={vehiculosLigeros} contacto={contacto} />} />
      <Route path="/vehiculos-pesados" 
        element={<VehiculosPesados vehiculosPesados={vehiculosPesados} contacto={contacto} /> } />
    </Routes>
  )
}
