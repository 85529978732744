import { FC } from 'react';
import styles from './styles.module.css'

interface Props {
  children: JSX.Element | string;
  funcion?: (e: any) => void;
}

export const Boton: FC<Props> = ({ children, funcion }) => {
  return (
    <button className={styles.a} onClick={funcion ? funcion : ()=>{window.scrollTo(0, 0)}}>
      {children}
    </button>
  )
}
