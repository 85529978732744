import { FC } from "react";
import { SeccionImagenLogo } from "../../../../components";
import { ServiciosResp } from "../../../../interfaces/servicios";

interface Props {
    servicios: ServiciosResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ servicios }) => {
    return (
        <section>
            <SeccionImagenLogo imagen={servicios?.acf.imagen_cover || ''} />
        </section>
    )
}
