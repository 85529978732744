import { FC } from 'react';
import { ListadoProductos } from '../../../../components';
import { Boton } from '../../../../components/Boton';

import { VehiculosLigerosResp } from '../../../../interfaces/vehiculosLigeros';

import styles from './styles.module.css'

interface Props {
    vehiculosLigeros: VehiculosLigerosResp | null;
}

export const TerceraSeccion: FC<Props> = ({ vehiculosLigeros }) => {
    return (
        <section>
            <ListadoProductos vehiculosLigeros={vehiculosLigeros} />
            <div className={styles['contenedor-boton']} >

                <Boton>Volver arriba</Boton>
            </div>
        </section>
    )
}
