import { FC, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { PrimeraSeccion, SegundaSeccion, TerceraSeccion, CuartaSeccion, QuintaSeccion, SextaSeccion, SeptimaSeccion } from '../../pages/home/sections';
import { InicioResp } from '../../interfaces';

interface Props{
    inicio: InicioResp[] | null;
}

export const Home: FC<Props> = ({inicio}) => {

    useEffect(()=>{
        document.title = 'Osania - Inicio';
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PrimeraSeccion inicio={inicio ? inicio[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{ backgroundColor: '#efefef' }}>
                    <TerceraSeccion inicio={inicio ? inicio[2] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SegundaSeccion inicio={inicio ? inicio[1] : null} />
                </Grid>
                
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CuartaSeccion inicio={inicio ? inicio[3] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <QuintaSeccion inicio={inicio ? inicio[4] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SextaSeccion inicio={inicio ? inicio[5] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{
                    backgroundColor: '#EEEEEE'
                }}>
                    <SeptimaSeccion inicio={inicio ? inicio[6] : null} />
                </Grid>
            </Grid>
        </Box>
    )
}
