import { FC, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './estilos.module.css';

import marca from '../../../../assets/img/otros/marca-agua.png';
import { InicioResp } from '../../../../interfaces';
import { Boton } from '../../../../components';

interface Props {
  inicio: InicioResp | null;
}

export const SeptimaSeccion: FC<Props> = ({ inicio }) => {
  const matches = useMediaQuery('(max-width:600px)');

  const [formulario, setFormulario] = useState({
    nombre: '',
    empresa: '',
    email: '',
    descripcion: '',
    phone: '',
  });

  return (
    <section>
      <div className={styles.contenedor}>

        <form className={styles.form}>
          <h3 className={styles.h3}>{inicio?.acf.titulo}</h3>
          <div className={styles['contenedor-inputs']}>
            <input className={`${styles.input} input-placeholder`} placeholder='NOMBRE:' onChange={(e) => {
              setFormulario({ ...formulario, nombre: e.target.value });
            }} />
            <input className={`${styles.input} input-placeholder`} placeholder='EMPRESA:' onChange={(e) => {
              setFormulario({ ...formulario, empresa: e.target.value });
            }} />
            <input className={`${styles.input} input-placeholder`} placeholder='TELÉFONO:' onChange={(e) => {
              setFormulario({ ...formulario, phone: e.target.value });
            }} />
            <input className={`${styles.input} input-placeholder`} placeholder='MAIL:' onChange={(e) => {
              setFormulario({ ...formulario, email: e.target.value });
            }} />
            <textarea className={styles['text-area']} placeholder='BREVE DESCRIPCIÓN DEL PROYECTO:' onChange={(e) => {
              setFormulario({ ...formulario, descripcion: e.target.value });
            }} />
            <div className="g-recaptcha" data-sitekey="6LdS8fsiAAAAADcZKe3DUHkJX1LlV7Z0CBZ3jVVv"></div>
            <div style={{marginTop: 10}}></div>
            <Boton funcion={(e) => {
              e.preventDefault();
              fetch('enviar.php', {
                method: 'POST',
                // eslint-disable-next-line no-undef
                body: JSON.stringify({ ...formulario, captcha: grecaptcha.getResponse() }),
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
              }).then((response) => {
                return response.json();
              }).then((json) => {
                if (json.success) {
                  alert(json.success);
                }
                if (json.error) {
                  alert(json.error);
                }
              });
            }}>Enviar</Boton>
            <Boton>Volver arriba</Boton>
          </div>
        </form>
        {!matches && <img className={styles.marca} src={marca} alt="" />}
      </div>
    </section>
  )
}
