import { FC, useEffect, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import useMediaQuery from '@mui/material/useMediaQuery';

import { InicioResp } from '../../../../interfaces';

import 'swiper/css';
import 'swiper/css/navigation';

import styles from './estilos.module.css';

interface Props{
    inicio: InicioResp | null;
}

export const QuintaSeccion: FC<Props> = ({inicio}) => {

    const matches = useMediaQuery('(max-width:600px)');
    const [titulo, setTitulo] = useState(['','']);

    useEffect(() => {
        if (inicio) {
            const primeraPalabra = inicio.acf.titulo?.split(' ')[0] || '';
            const resto = inicio.acf.titulo?.replace(`${primeraPalabra} `,'') || '';
            setTitulo([primeraPalabra,resto]);
        }

    }, [inicio])

    return (
        <section className='seccion-slider-clientes'>
            <div className={styles.contenedor}>
                {matches ? <h3 className={styles.h3}>{inicio?.acf.titulo}</h3>  :
                <h3 className={styles.h3}>{titulo[0]} <br /> {titulo[1]}</h3>}
                <div className={styles['contenedor-slider']}>
                    <Swiper
                        modules={[Navigation]}
                        navigation
                        slidesPerView={ matches ? 2 : 4}
                        onSlideChange={() => { }}
                        onSwiper={(swiper) => { }}
                    >

                        {
                            inicio?.acf.clientes?.map(({imagen}) => (
                                <SwiperSlide key={imagen}>
                                    <div className={styles['contenedor-imagen']}>
                                        <img className={styles.img} src={imagen} alt="" />
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    )
}
