import { FC } from "react";

import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useInView } from 'react-hook-inview'

import parse from 'html-react-parser';

import { obtenerClasesAnimacion, obtenerPrimerasLetras, obtenerUltimasLetras } from "../../utils";
import { TextoSubrayado } from "../TextoSubrayado";

import styles from './styles.module.css'

import { HashLink as Link } from '@xzar90/react-router-hash-link';

interface Props {
    color: string;
    imagen: string;
    parrafo: string;
    titulo_principal: string;
    titulo_secundario: string;
}

export const CartaServicios: FC<Props> = ({ color, imagen, parrafo, titulo_principal, titulo_secundario }) => {
    const matches = useMediaQuery('(max-width:600px)');
    const [contenedor, isVisible] = useInView({
        threshold: 0,
        unobserveOnEnter: true
    })
    return (
        <Grid container spacing={1} sx={{
            minHeight: 669,
            backgroundColor: color,
            padding: matches ? '20px 0px' : '0px'
        }}>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>

            <Grid container item xl={10} lg={10} md={10} sm={10} xs={10}>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div ref={contenedor}
                        className={`${isVisible ? `${obtenerClasesAnimacion('animate__fadeIn')} ${styles['contenedor-texto-subrayado']}` 
                        : `${styles['contenedor-texto-subrayado']}`}`}>
                        <TextoSubrayado
                            estiloContenedor={{
                                position: 'relative',
                                zIndex: 20,
                                color: '#1d3d62',
                                fontSize: matches ? 26 : 40,
                                fontWeight: 100,
                                whiteSpace: matches ? 'initial' : 'pre',
                                margin: 0,
                                padding: matches ? '5px 0px' : '36px 0px',
                                width: '100%',
                            }}
                            textos={[
                                `     ${obtenerPrimerasLetras(titulo_principal, 5)}`,
                                obtenerUltimasLetras(titulo_principal, 5)
                            ]}
                            estilosTextos={[
                                {
                                    borderBottom: matches ? 'none' : '4px solid #eea660'
                                }
                            ]}
                        />
                    </div>
                </Grid>

                <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12} className={styles['contenedor-imagen']}>
                        <img className={styles.img} src={imagen} alt="" />
                    </Grid>

                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>

                    <Grid container item xl={7} lg={7} md={12} sm={12} xs={12}>
                        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid className={styles['contenedor-titulo']} item xl={10} lg={9} md={10} sm={10} xs={12} sx={{
                                marginTop: matches ? 3 : 0
                            }}>
                                <h3 className={styles.h3}>{titulo_secundario}</h3>
                            </Grid>
                            <Grid className={styles['contenedor-titulo']} item xl={2} lg={3} md={2} sm={2} xs={12} sx={{
                                marginTop: matches ? 3 : 0
                            }}>
                                <Link className={styles.btn} to="/servicios#contacto">
                                    Cotizar
                                </Link>
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} sx={{
                                marginTop: matches ? 3 : 0
                            }}>
                                <p className={styles.p}>
                                    {parse(parrafo)}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>

            </Grid>

            <Grid item xl={1} lg={1} md={1} sm={1} xs={1}></Grid>
        </Grid>
    )
}
