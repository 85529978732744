import Grid from "@mui/material/Grid";

import useMediaQuery from '@mui/material/useMediaQuery';

import styles from './estilos.module.css';

import logo from '../../assets/img/otros/logotipo-blanco.png';

import facebook from '../../assets/icons/facebook.png';
import linkedin from '../../assets/icons/linkedin.png';
import whatsapp from '../../assets/icons/whatsapp.png';

export const Footer = () => {
    const matches = useMediaQuery('(max-width:600px)');
    return (
        <footer>
            <Grid container spacing={2} sx={{
                backgroundColor: '#2d2d2d',
                height: matches ? 'auto' : 470,
                paddingBottom: matches ? '20px' : 0
            }}>

                <Grid item xl={1} lg={1} xs={1}></Grid>

                {
                    //Footer responsivo
                    matches && <>
                        <Grid container item xs={4}>
                            <Grid item xl={1} lg={1} xs={12}>
                                <h3 className={styles.h3}>Servicios</h3>
                                <p className={styles.p}>Vehículos</p>
                                <p className={styles.p}>Industrial</p>
                            </Grid>
                            <Grid item xl={1} lg={1} xs={12}>
                                <h3 className={styles.h3}>Osania</h3>
                                <p className={styles.p}>Nosotros</p>
                                <p className={styles.p}>Clientes</p>
                                <p className={styles.p}>Contacto</p>
                                <a href="https://osania.com/aviso.pdf" rel="noreferrer" target="_blank" className={`${styles.p} ${styles.link}`}>
                                    Aviso de privacidad
                                </a>
                            </Grid>
                        </Grid>
                    </>
                }

                {
                    //Footer no responsivo
                    !matches && <>
                        <Grid item xl={1} lg={1} xs={2}>
                            <h3 className={styles.h3}>Servicios</h3>
                            <p className={styles.p}>Vehículos</p>
                            <p className={styles.p}>Industrial</p>
                        </Grid>

                        <Grid item xl={1} lg={1} xs={1}></Grid>

                        <Grid item xl={1} lg={1} xs={2}>
                            <h3 className={styles.h3}>Osania</h3>
                            <p className={styles.p}>Nosotros</p>
                            <p className={styles.p}>Clientes</p>
                            <p className={styles.p}>Contacto</p>
                            <a href="https://osania.com/aviso.pdf" rel="noreferrer" target="_blank" className={`${styles.p} ${styles.link}`}>
                                Aviso de privacidad
                            </a>
                        </Grid>
                    </>
                }

                <Grid item xl={5} lg={5} xs={1}></Grid>

                {matches && <Grid item xl={3} lg={3} xs={1}></Grid>}

                <Grid item xl={2} lg={2} xs={5}>
                    <h3 className={`${styles.h3}`}>Contáctanos</h3>
                    <p className={styles.p}>44 2183 4633</p>
                    <p className={styles.p}>44 2252 3364</p>
                    <p className={`${styles.p} ${styles['p-whatsapp']}`}>
                        <a href="https://api.whatsapp.com/send?phone=524421834633&text=Hola,%20me%20gustar%C3%ADa%20saber%20m%C3%A1s%20sobre%20Osania" target="_blank" rel="noreferrer">
                            <img className={styles.icono} src={whatsapp} alt="" />
                        </a> 442 183 4633
                    </p>
                    <p className={styles.p}>
                        <a className={styles.a} href="mailto:info@osania.com">info@osania.com</a>
                    </p>
                    <p className={styles.p}>Link redes</p>

                    <div>
                        <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                            <img className={styles.icono} src={facebook} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                            <img className={styles.icono} src={linkedin} alt="" />
                        </a>

                    </div>
                </Grid>

                <Grid item xl={1} lg={1} xs={1}></Grid>
            </Grid>
            <Grid container spacing={2} sx={{
                backgroundColor: '#1a1a1a',
                height: matches ? 'auto' : 247,
            }}>
                <Grid item xl={1} lg={1} xs={1}></Grid>

                <Grid item xl={3} lg={3} xs={5}>
                    <p className={`${styles.p} ${styles.derechos}`}>©OSANIA Todos los derechos reservados.</p>
                </Grid>

                <Grid item xl={5} lg={5} xs={1}></Grid>

                <Grid item xl={2} lg={2} xs={4}>
                    <img className={styles.logo} src={logo} alt="" />
                </Grid>

                <Grid item xl={1} lg={1} xs={1}></Grid>
            </Grid>
        </footer>
    )
}
