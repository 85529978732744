import { CSSProperties, FC } from "react";

import styles from '../Titulo/estilos.module.css';

interface Props {
  titulo: string | JSX.Element;
  css?: CSSProperties;
}

export const Titulo: FC<Props> = ({ titulo, css }) => {
  return (
    <div className={styles['contenedor-titulo']} style={css}>

      <h3 className={styles.titulo}>{titulo}</h3>
    </div>
  )
}
