import { FC } from "react";
import { Boton, ListadoProductos } from "../../../../components";
import { RetornableResp } from "../../../../interfaces/retornable";

import styles from './styles.module.css'

interface Props {
    retornable: RetornableResp | null;
}

export const TerceraSeccion: FC<Props> = ({ retornable }) => {
    return (
        <section>
            <ListadoProductos piezas={retornable} />
            <div className={styles['contenedor-boton']} >

                <Boton>Volver arriba</Boton>
            </div>
        </section>
    )
}
