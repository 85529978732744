import { FC } from 'react';
import { useInView } from 'react-hook-inview';

import styles from './estilos.module.css';

import marca from '../../../../assets/img/home/secciones/sexta/marca-agua.png';

import agua from '../../../../assets/img/home/secciones/sexta/agua-removebg-preview.png';
import gota from '../../../../assets/img/home/secciones/sexta/gota-removebg-preview.png';
import { InicioResp } from '../../../../interfaces';
import { obtenerClasesAnimacion } from '../../../../utils';

interface PropsContenedorCuadro {
  imagen: string;
  texto: string;
}

const ContenedorCuadro: FC<PropsContenedorCuadro> = ({ imagen, texto }) => {
  return (
    <div className={styles.cuadro}>
      <img className={styles.icono} src={imagen} alt="" />
      <hr className={styles.hr} />
      <p className={styles['p-cuadro']}>
        {texto}
      </p>
    </div>
  )
}

const imagenes = [
  agua,
  gota,
  gota,
  agua
]

interface Props {
  inicio: InicioResp | null;
}

export const SextaSeccion: FC<Props> = ({ inicio }) => {
  const [contenedorTitulo, contenedorTituloIsVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true
  })
  const [contenedorCuadros, contenedorCuadrosIsVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true
  })
  

  return (
    <section className={styles.contenedor}>
      <img className={styles['marca-agua']} src={marca} alt="" />
      <div className={styles.contenido}>
        <div ref={contenedorTitulo} 
          className={`${styles['contenedor-texto']} ${contenedorTituloIsVisible ? obtenerClasesAnimacion('animate__backInUp',2) : ''}`}>
          <h3 className={styles.h3}>
            Creamos <br />
            Innovamos <br />
            solucionamos <br />
          </h3>
          <p className={styles.p}>
            {inicio?.acf.parrafo}
          </p>
        </div>
        <div ref={contenedorCuadros}
          className={`${styles['contenedor-cuadros']} ${contenedorCuadrosIsVisible ? obtenerClasesAnimacion('animate__fadeIn',2,2) : ''}`}>
          {
            inicio?.acf.secciones?.map(({ texto }, index) => (
              <ContenedorCuadro key={`cuadros-${index}`}
                imagen={imagenes[index]}
                texto={texto} />
            ))
          }
        </div>
      </div>
    </section>
  )
}
