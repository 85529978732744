import img1 from '../../assets/img/home/carrucelMobile/carrusel1.webp';
import img2 from '../../assets/img/home/carrucelMobile/carrusel2.webp';
import img3 from '../../assets/img/home/carrucelMobile/carrusel3.webp';
import img4 from '../../assets/img/home/carrucelMobile/carrusel4.webp';
import img5 from '../../assets/img/home/carrucelMobile/carrusel5.webp';

export const imagenesMobile = [
    img1,
    img2,
    img3,
    img4,
    img5,
]