import { FC, useEffect } from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContactoResp } from '../../interfaces/contacto';
import { VehiculosPesadosResp } from '../../interfaces/vehiculosPesados';
import { PrimeraSeccion, SegundaSeccion, TerceraSeccion, CuartaSeccion } from '../../pages/vehiculosPesados/sections';


interface Props {
    vehiculosPesados: VehiculosPesadosResp[] | null;
    contacto: ContactoResp | null;
}

export const VehiculosPesados: FC<Props> = ({ vehiculosPesados, contacto }) => {
    useEffect(()=>{
        document.title = 'Osania - Vehículos Pesados';
        window.scrollTo({top: 0, behavior: 'auto'});
    },[])
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <PrimeraSeccion vehiculosPesados={vehiculosPesados ? vehiculosPesados[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <SegundaSeccion vehiculosPesados={vehiculosPesados ? vehiculosPesados[1] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <TerceraSeccion vehiculosPesados={vehiculosPesados ? vehiculosPesados[0] : null} />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <CuartaSeccion contacto={contacto} />
                </Grid>
            </Grid>
        </Box>
    )
}
