import { FC } from "react";
import { SeccionImagenLogo } from "../../../../components";
import { RetornableResp } from "../../../../interfaces/retornable"


interface Props {
  retornable: RetornableResp | null;
}

export const PrimeraSeccion: FC<Props> = ({ retornable }) => {
  return (
    <section>
      <SeccionImagenLogo imagen={retornable?.acf.imagen_cover || ''} />
    </section>
  )
}
