import { FC } from 'react';
import { SeccionTextoImagen } from '../../../../components'
import { PiezasResp } from '../../../../interfaces/piezas'

interface Props {
    piezas: PiezasResp | null;
}

export const SegundaSeccion: FC<Props> = ({ piezas }) => {
    return (
        <section>
            <SeccionTextoImagen 
                tituloPrincipal={piezas?.acf.titulo || ''}
                texto={piezas?.acf.parrafo || ''}
                imagen={piezas?.acf.imagen_secundaria || ''} />
        </section>
    )
}
