import { FC } from 'react';

import styles from './styles.module.css'

import logo from '../../assets/img/otros/logotipo-blanco.png'

import sombra from '../../assets/img/carrucel/sombra carrusel.png'
import { Link } from 'react-router-dom';

interface Props {
    imagen: string;
}

export const SeccionImagenLogo: FC<Props> = ({ imagen }) => {
    return (
        <div className={styles.contenedor}>
            <img className={styles.sombra} src={sombra} alt="" />
            <img className={styles.img} src={imagen} alt="" />
            <Link to="/">
                <img className={styles.logo} src={logo} alt="" />
            </Link>
        </div>
    )
}
