import { FC, useRef, useState } from 'react';

import styles from './estilos.module.css';

import flecha from '../../assets/img/home/secciones/segunda/flecha-naranja.png';
import { animateCSS } from '../../utils';

interface Item {
    titulo: string;
    imagen: string;
}

interface Props {
    items: Item[];
}

export const MenuConImagen: FC<Props> = ({ items }) => {

    const [index, setIndex] = useState(0);
    const imagen = useRef<HTMLImageElement | null>(null);

    const handleClick = (i: number) => {
        setIndex(i);
    }

    return (
        <>
            <ul className={styles.lista}>
                {items.map(({ titulo }, i) => (
                    <li key={titulo} className={styles.li}>
                        <h4  className={index === i ?
                            `${styles['opcion-seleccionada']}` : `${styles.opcion}`}
                            onClick={() => handleClick(i) }>
                            <img className={index === i ?
                                `${styles['flecha-seleccionada']}` : `${styles.flecha}`} src={flecha} alt="icono" />
                            {titulo}
                        </h4>

                    </li>
                ))}
            </ul>

            <img ref={imagen} className={styles.img} src={items[index].imagen} alt="foto" 
                onLoad={()=>animateCSS(imagen.current!,'fadeIn')} />
        </>
    )
}
