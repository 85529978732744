import { FC } from "react";
import { SeccionTextoImagen } from "../../../../components";
import { VehiculosPesadosResp } from "../../../../interfaces/vehiculosPesados";

interface Props {
    vehiculosPesados: VehiculosPesadosResp | null;
}

export const SegundaSeccion: FC<Props> = ({ vehiculosPesados }) => {
    return (
        <section>
            <SeccionTextoImagen 
                imagen={vehiculosPesados?.acf.imagen_secundaria || ''} 
                texto={vehiculosPesados?.acf.parrafo || ''} 
                tituloPrincipal={vehiculosPesados?.acf.titulo || ''} />
        </section>
    )
}
