import { FC, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { CuartaSeccion, PrimeraSeccion, SegundaSeccion, TerceraSeccion } from '../../pages/piezas/sections';
import { PiezasResp } from '../../interfaces/piezas';
import { ContactoResp } from '../../interfaces/contacto';

interface Props {
  piezas: PiezasResp[] | null;
  contacto: ContactoResp | null;
}

export const Piezas: FC<Props> = ({ piezas, contacto }) => {
  useEffect(() => {
    document.title = 'Osania - Piezas';
    window.scrollTo({top: 0, behavior: 'auto'});
  }, [])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PrimeraSeccion piezas={piezas ? piezas[1] : null} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <SegundaSeccion piezas={piezas ? piezas[1] : null} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TerceraSeccion piezas={piezas ? piezas[0] : null} />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <CuartaSeccion contacto={contacto || null} />
        </Grid>
      </Grid>
    </Box>
  )
}
