export const obtenerPrimerasLetras = (palabra: string, num: number) => {
    let substring = '';
    for (let i = 0; i < num; i++) {
        substring += palabra[i];
    }
    return substring;
}

export const obtenerUltimasLetras = (palabra: string, num: number) => {
    let substring = '';
    for(let i = num; i < palabra.length; i++){
        substring += palabra[i];
    }
    return substring;
}