export const animateCSS = (element: Element, animation: string, prefix = 'animate__') => {
    // We create a Promise and return it

    const animationName = `${prefix}${animation}`;
    const node = element;

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: any) {
        event.stopPropagation();
        node.classList.remove(`${prefix}animated`, animationName);
    }

    node.addEventListener('animationend', handleAnimationEnd, { once: true });
}

type vel = 1 | 2 | 3 | 800 | 500;
type del = 1 | 2 | 3 | 4 | 5;

export const obtenerDelay = (delay: del = 1) => {
    switch (delay) {
        case 2:
            return 'animate__delay-2s';
        case 3:
            return 'animate__delay-3s';
        case 4:
            return 'animate__delay-4s';
        case 5:
            return 'animate__delay-5s';

        default:
            return '';
    }
}

export const obtenerClasesAnimacion = (animacion: string, velocidad: vel = 1, delay: del = 1) => {
    switch (velocidad) {
        case 2:
            return `animate__animated ${animacion} ${obtenerDelay(delay)} animate__slow`;
        case 3:
            return `animate__animated ${animacion} ${obtenerDelay(delay)} animate__slower`;
        case 800:
            return `animate__animated ${animacion} ${obtenerDelay(delay)} animate__fast`;
        case 500:
            return `animate__animated ${animacion} ${obtenerDelay(delay)} animate__faster`;
        default:
            return `animate__animated ${animacion} ${obtenerDelay(delay)}`;
    }

}