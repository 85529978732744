import { HashRouter } from 'react-router-dom';
import { Footer, Header } from './components';
import { Rutas } from './routes';

import 'animate.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Header/>
        <Rutas />
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
