import { FC } from 'react';
import { Boton, ListadoProductos } from '../../../../components';

import { PiezasResp } from "../../../../interfaces/piezas"

import styles from './styles.module.css'

interface Props {
    piezas: PiezasResp | null;
}


export const TerceraSeccion: FC<Props> = ({ piezas }) => {

    return (
        <section>
            <ListadoProductos piezas={piezas} />
            <div className={styles['contenedor-boton']} >

                <Boton>Volver arriba</Boton>
            </div>
        </section>
    )
}
